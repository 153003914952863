import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import apiConfig from "src/config/APICongig";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("creatturAccessToken");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData] = useState({});
  const [cmcResults, setCMCResults] = useState([]);
  const [loader, setLoader] = useState(false);
  const accessToken = localStorage.getItem("token");

  let data = {
    userLoggedIn: isLogin,
    userData,
    cmcResults,
    loader,

    // CmcAPIHandler: () => CmcAPIHandler(),

    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  const CmcAPIHandler = async () => {
    // setLoader(true);
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.messari,
      });

      if (res) {
        setCMCResults(res?.data?.result?.data);
        setLoader(false);
      }
    } catch (error) {
      console.log("ERROR", error);
      setLoader(false);
    }
  };

  // useEffect(() => {
  //   CmcAPIHandler();
  //   // setInterval(() => {
  //   //   CmcAPIHandler();
  //   // }, 5000);
  // }, []);

  // useEffect(() => {
  //   CmcAPIHandler();
  // }, []);

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
