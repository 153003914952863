import React from "react";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  imgSection: {
    "& img": {
      // maxWidth: "230px",
      maxWidth: "195px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 auto",
    },

    "@media(max-width:991px)": {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      // margin: "0 auto",
    },
  },
}));

const Logo = (props) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.imgSection}>
        <img
          src="/images/VD1.png"
          alt="Logo"
          {...props}
          // className="logoImg"
          style={{ width: "195px" }}
        />
      </Box>
    </>
  );
};

export default Logo;
