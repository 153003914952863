export default {
  h1: {
    fontWeight: 700,
    fontSize: 50,
    fontFamily: "'Inter', sans-serif",
    "@media(max-width:767px)": {
      fontSize: "40px !important",
    },
  },
  h2: {
    fontWeight: 600,
    fontSize: 40,
    fontFamily: "'Inter', sans-serif",
    color: "#1D2D3F",
    "@media(max-width:767px)": {
      fontSize: "27px !important",
    },
  },
  h3: {
    fontWeight: 600,
    fontSize: 35,
    fontFamily: "'Inter', sans-serif",
    "@media(max-width:767px)": {
      fontSize: 23,
    },
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    "@media(max-width:767px)": {
      fontSize: 16,
    },
  },
  h5: {
    fontWeight: 500,
    fontSize: 18,
    "@media(max-width:767px)": {
      fontSize: "16px !important",
    },
  },
  h6: {
    fontWeight: 500,
    fontSize: 30,
    fontFamily: "'Inter', sans-serif",
    "@media(max-width:605px)": {
      fontSize: "16px !important",
    },
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
  },
  body1: {
    fontSize: "15px",
    fontFamily: "'Inter', sans-serif",
    fontWeight: "400",
    lineHeight: "25px",
    color: "#77808B",
    "@media(max-width:605px)": {
      fontSize: "14px !important",
    },
  },
  body2: {
    fontSize: "14px",
    fontFamily: "'Inter', sans-serif",
    fontWeight: "400",
    lineHeight: "22px",
    color: "#1D2D3F",
    "@media(max-width:605px)": {
      fontSize: "14px !important",
      lineHeight: "20px",
    },
  },
};
