import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  Box,
  Container,
  Popper,
  List,
  ListItem,
  Menu,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  withStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import Scroll from "react-scroll";
import { AiOutlineCaretDown } from "react-icons/ai";
import { AuthContext } from "src/context/Auth";
import { ExpandMore } from "@material-ui/icons";
import { BiChevronDown } from "react-icons/bi";
const ScrollLink = Scroll.Link;

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: "#fff",
    // "&:focus": {
    //   backgroundColor: theme.palette.primary.main,
    //   "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
    //     color: theme.palette.common.white,
    //   },
    // },
  },
}))(MenuItem);

const StyledMenu = withStyles((theme) => ({
  paper: {
    width: "102px !important",
    background:
      "linear-gradient(180deg, #00ACEB 0%, #00B0ED 10.18%, #1069C2 70.35%, #1069C2 100%)",
    border: "1px solid #e1e1e1",
    borderTop: "3px solid #5a86ff",
    borderRadius: 0,
    padding: "6px",
    "@media(max-width:1024px)": {
      left: "780px !important",
    },
    "@media(max-width:912px)": {
      left: "570px !important",
    },
    "@media(max-width:820px)": {
      left: "672px !important",
    },

    "@media(max-width:768px)": {
      left: "521px !important",
    },
    "@media(max-width:668px)": {
      left: "321px !important",
    },

    "@media(max-width:441px)": {
      left: "146px !important",
    },
    "@media(max-width:393 px)": {
      left: "139px !important",
    },
    "@media(max-width:375px)": {
      left: "113px !important",
    },
    "@media(max-width:360px)": {
      left: "110px !important",
    },
    "@media(max-width:320px)": {
      left: "63px !important",
    },
    "@media(max-width:280px)": {
      left: "20px !important",
    },
  },
}))((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  joinButton: {
    [theme.breakpoints.down("md")]: {},
  },
  labeltext: {
    padding: "0em 0em 0.7em",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "27px",
    color: "#fff",
    display: "block",
    whiteSpace: "nowrap",
    verticalAlign: "baseline",
    borderRadius: "0.25em",
  },
  dialoginputbox: {
    width: "393px",
    height: "35px",
    border: "2px solid #fff",
    paddingLeft: "10px",
    color: "#fff",
    backgroundColor: "#141414",
    borderRadius: "8px",
    // boxShadow: "2px 5px 2px #888888ab",

    "@media (max-width: 900px)": {
      width: "291px",
    },
  },
  launchButton: {
    [theme.breakpoints.down("xs")]: {
      padding: "10px 30px !important",
      marginTop: "9px",
    },
  },
  menuButton: {
    fontSize: "13px",
    fontWeight: "500",
    borderRadius: 0,
    minWidth: "auto",
    color: "#fff",
    height: "31px",
    padding: "0px 7px",
    letterSpacing: "1px",
    marginLeft: "15px",
    textDecoration: "none",
    lineHeight: "54px",
    fontFamily: "'Inter'",
    cursor: "pointer",
    "& span": {
      display: "flex",
      alignItems: "center",
    },

    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "12px 0px !important",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      height: "auto",
    },
    "&.activeMenu": {
      background: "#fff",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
      position: "relative",
      "&:before": {
        content: "' '",
        position: "absolute",
        top: "26px",
        left: " 0px",
        width: "100%",
        height: "5px",
        background:
          "linear-gradient(180deg, #00ACEB 0%, #00B0ED 10.18%, #1069C2 70.35%, #1069C2 100%)",
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },

    "&[tabIndex]:active": {
      color: "#19AAE6",
    },
    "&:hover": {
      color: "#fff",
    },
  },

  toolbar: {
    display: "flex",
    padding: "8px 0",
    justifyContent: "space-between",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "150px !important",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#3077ca",
    width: "260px",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "8%",
    right: "0px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
    minHeight: "60px !important",
  },

  menuLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  inerrMenu: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    whiteSpace: "nowrap",
    paddingLeft: "25px",
    alignItems: "center",
    // justifyContent: "flex-end",
    justifyContent: "flex-start",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "block",
      padding: "0",
    },
    "& li": {
      "&.active": {
        background: "red",
      },
    },
  },

  menuul: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    alignItems: "center",
    // margin: "0",
    height: "30px",
    justifyContent: "space-between",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "block",
      "& .logoDesk": {
        display: "none",
      },
    },

    "& .buttonRound": {
      width: "41px",
      height: "41px",
      borderRadius: "50%",
      color: "#fff",
      marginLeft: "15px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "20px",
      cursor: "pointer",
      border: "2px solid #FFFFFF",
      [theme.breakpoints.down("md")]: {
        marginBottom: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "20px",
      },
      "&:hover": {
        background: "#fff",
        color: "#000",
        // border: "2px solid #000",
      },
    },
  },

  topBox: {},

  StartPage: {
    background:
      "linear-gradient(93deg, #06246d 0%, #04256c 10.18%, #1f72d1 70.35%, #3a98f0 100%)",
    left: "auto",
    top: "0",
    backdropFilter: "blur(44px)",
    position: "fixed !important",
    // -webkit-backdrop-filter: ;
  },

  endPage: {
    backgroundColor: "#3077ca !important",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },

  ButtonLogin: {
    marginTop: "30px",
    boxShadow: "none",
    background: "none",
    border: "1px solid #00B0ED",
    "&:hover": {
      boxShadow:
        "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
      background:
        "linear-gradient(180deg, #00ACEB 0%, #00B0ED 10.18%, #1069C2 70.35%, #1069C2 100%)",
      border: "none",
    },
  },
  signinBox: {
    height: "100%",
    borderLeft: " 1px solid",
    borderColor: theme.palette.background.border,
    [theme.breakpoints.down("md")]: {
      borderLeft: " none",
    },
    "& button": {
      width: "100%",
      height: "100%",
      color: "#fff",
      fontSize: "12px !important",
      [theme.breakpoints.down("md")]: {
        width: "auto",
        height: "auto",
        color: "#fff",
        height: " 60px",
      },
      "& label": {
        fontSize: "14px !important",
      },
    },
  },
}));

export default function Header({ buttonClick }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  //Popper
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // console.log("anchorEl-----", anchorEl);
  const auth = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(anchorEl ? null : event.currentTarget);
  // };

  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const openpopper = Boolean(anchorEl);
  const id = openpopper ? "simple-popper" : undefined;
  //Popper end
  const handleClose = () => {
    setOpen(false);
  };
  const {
    toolbar,
    menuul,
    drawerContainer,
    drawericon,
    menuLeft,
    logoDrawer,
    containerHeight,
    inerrMenu,
    mainHeader,
    menuButton,
    launchButton,
    StartPage,
    endPage,
    formControl,
    paper,
    ButtonLogin,
    signinBox,
    menutext1,
  } = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const { mobileView, drawerOpen } = state;
  const headercolor = window.location.href.split("/")[3];
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1280
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Container className="p-0">
        <Toolbar className={toolbar}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            <Grid item xs={12} align="center">
              {menuText}
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            {/* <img
              className={logoDrawer}
              // src="images/lightlogo.png"
              src="images/VD1.png"
              alt=""
              style={{ width: "150px" }}
            /> */}

            <Link to="/" style={{ display: "inherit" }}>
              <img
                src="/images/VD1.png"
                alt="logo"
                className="logoImg"
                style={{ width: "150px" }}
              />
            </Link>

            {menuText}
          </div>
        </Drawer>

        <div>{productLogo}</div>

        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#fff", fontSize: "35px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const productLogo = (
    <Box display="flex" alignItems="center">
      <Link to="/" style={{ display: "inherit" }}>
        <img
          src="/images/VD1.png"
          alt="logo"
          className="logoImg"
          style={{ width: "150px" }}
        />
      </Link>
    </Box>
  );

  const menuText = (
    <nav>
      <ul class={menuul}>
        <Box className={menuLeft}>
          <div className="logoDesk">
            <ScrollLink
              className={menuButton}
              smooth={true}
              duration={500}
              to="section1"
            >
              {" "}
              {productLogo}
            </ScrollLink>
          </div>
        </Box>
        <li>
          <li className="activecolor">
            <ul className={inerrMenu}>
              <li>
                <ScrollLink
                  smooth={true}
                  duration={500}
                  // to="tokenomic"
                  // exact
                  activeClassName="activeMenu"
                  className={menuButton}
                  // to="/"
                  to="token"
                  onClick={() => history.push("/?id=token")}
                >
                  {" "}
                  Token
                </ScrollLink>{" "}
              </li>

              <li>
                <ScrollLink
                  smooth={true}
                  duration={500}
                  // to="tokenomic"
                  // exact
                  activeClassName="activeMenu"
                  className={menuButton}
                  // to="/"
                  to="wallet"
                  tabIndex="2"
                  onClick={() => history.push("/?id=wallet")}
                >
                  {" "}
                  Wallet
                </ScrollLink>{" "}
              </li>

              <li>
                <ScrollLink
                  smooth={true}
                  duration={500}
                  // to="tokenomic"
                  // exact
                  activeClassName="activeMenu"
                  className={menuButton}
                  // to="/"
                  tabIndex="2"
                  to="ecosystem"
                  // tabIndex="2"
                  onClick={() => history.push("/?id=ecosystem")}
                >
                  {" "}
                  Ecosystem
                </ScrollLink>{" "}
              </li>

              <li>
                <ScrollLink
                  smooth={true}
                  duration={500}
                  activeClassName="activeMenu"
                  className={menuButton}
                  to="marketplace"
                  onClick={() => history.push("/?id=marketplace")}
                >
                  {" "}
                  Marketplace{" "}
                </ScrollLink>{" "}
              </li>

              <li>
                <ScrollLink
                  smooth={true}
                  duration={500}
                  activeClassName="activeMenu"
                  className={menuButton}
                  to="offerings"
                  onClick={() => history.push("/?id=offerings")}
                >
                  {" "}
                  Offshore Banking
                </ScrollLink>{" "}
              </li>

              <Popper id={id} open={open} anchorEl={anchorEl}>
                <div className={classes.paper}>The content of the Popper.</div>
              </Popper>

              <Button
                target="_blank"
                style={{ marginLeft: "16px" }}
                variant="contained"
                // href="https://digitalbank.mobiloitte.org/login"
                href="https://app.vdbcbank.com/"
              >
                Login
              </Button>
            </ul>
          </li>
        </li>
      </ul>
    </nav>
  );

  return (
    <>
      <AppBar
        style={
          headercolor == ""
            ? { backgroundColor: "transparent", position: "absolute" }
            : {
                backgroundColor: "transparent",
                border: "none",
                position: "absolute",
                top: "0",
              }
        }
        position="relative"
        elevation={0}
        className={
          history.location.pathname === "/team"
            ? endPage
            : history.location.pathname === "/about"
            ? endPage
            : history.location.pathname === "/terms"
            ? endPage
            : history.location.pathname === "/disclaimer"
            ? endPage
            : history.location.pathname === "/privacy-policy"
            ? endPage
            : history.location.pathname === "/faqs"
            ? endPage
            : history.location.pathname === "/legal"
            ? endPage
            : StartPage
        }
      >
        <Container maxWidth="fixed" className={containerHeight}>
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
    </>
  );
}
