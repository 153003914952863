import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  makeStyles,
  TextField,
  Button,
  FormHelperText,
  Link,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import * as yep from "yup";
// import Scroll from "react-scroll";
import apiConfig from "src/config/APICongig";

import { Link as RouterLink } from "react-router-dom";
import { AiOutlineTwitter } from "react-icons/ai";
import Scroll from "react-scroll";
import { FaDiscord, FaFacebookF, FaBitcoin } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import Tooltip from "@material-ui/core/Tooltip";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { toast } from "react-toastify";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import RedditIcon from "@material-ui/icons/Reddit";
import { SiMedium } from "react-icons/si";
import { IoLogoSlack } from "react-icons/io";

const ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  colorFFF: {
    color: "#fff",
  },
  LogoStyle: {
    width: "195px",
    [theme.breakpoints.down("sm")]: {
      width: "170px !important",
    },
  },
  footerSection: {
    background: "rgba(5, 74, 165, 1)",
    "& .MuiListItem-root": {
      padding: "5px 0px",
    },
    backgroundColor: theme.palette.background.taf,
    // background: "#F3F5F6",
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      paddingTop: 0,
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
    },
    "& h1": {
      fontWeight: "600",
      fontSize: "31px",
      [theme.breakpoints.down("md")]: {
        fontSize: "33px",
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: "23px",
      },
    },
    "& h6": {
      fontWeight: "400",
      fontSize: "14px",

      color: "#B7B7B7",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    "& h3": {
      fontWeight: "600",
      fontSize: "18px",

      color: "#2C4569",
      marginBottom: "15px",

      [theme.breakpoints.down("xs")]: {
        fontSize: "17px",
        whiteSpace: "pre",
      },
    },
    "& h4": {
      fontWeight: "600",
      fontSize: "18px",
      color: theme.palette.text.primary,
      // color: "#2C4569",
      marginBottom: "15px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "17px",
      },
    },
    "& .subpart": {
      borderBottom: "",
      // backgroundColor: theme.palette.background.taf,
      // backgroundColor: "#F3F5F6",
      // padding: "60px 10px 24px 10px",
      "& h6": {
        fontSize: "12px",
        color: "#848484",
        fontWeight: "300",
        lineHeight: "19px",
        width: "100%",
        maxWidth: "265px",
        "@media(max-width:730px)": {
          fontSize: "12px",
          maxWidth: "264px",
        },
        "@media(max-width:599px)": {
          fontSize: "13px",
          width: "100%",
          maxWidth: "100%",
          marginBottom: "10px",
        },
      },
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "300",
      maxWidth: "700px",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },
    },
  },
  baseSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& p": {
      color: "#B7B7B7",
      fontSize: "12px",

      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    "@media(max-width:565px)": {
      display: "flex",
      flexDirection: "center",
    },
  },
  textFeild: {
    backgroundColor: "#fff",
    boxShadow: "0px 87px 110px 101px rgb(57 49 117 / 10%)",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "11px",
    borderRadius: "10px",
    "& input": {
      // color: `${theme.palette.text.primary} !important`,
      color: "#000 !important",
      paddingLeft: "10px",
    },
  },

  icon: {
    cursor: "pointer",
    color: "#1048a1",
    background: "#ffffff",
    marginRight: "10px",
    fontSize: "18px",
    padding: "8px",
    borderRadius: "25px",
    "& :hover": {
      color: "#3DA1E5",
    },
    "@media(max-width:400px)": {
      marginRight: "6px",
    },
  },

  bin: {
    color: theme.palette.text.primary,
  },

  socialIcons: {
    display: "flex",
    justifyContent: "center",
  },
  textBtn: {
    color: "#fff",
    width: "160px",
    fontSize: "15px",
    padding: "10px 10px !important",
    background:
      "linear-gradient(180deg, #00ACEB 0%, #00B0ED 10.18%, #1069C2 70.35%, #1069C2 100%)",
    borderRadius: "10px",
    fontWeight: "500",
  },
  listbox: {
    background: "none",
    border: "none",
    margin: "0px",
    "& a": {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "14px",
      lineHeight: "26px",
      color: "#fff !important",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },

      "& :hover": {
        color: "#1069C2 ",
      },
    },
  },
  feildBox: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& .MuiOutlinedInput-root": {
      position: "relative",
      borderRadius: "10px",
      background: "#fff",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },
}));

function BootstrapTooltip(props) {}
export default function Liquidity() {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [scribe, setScribe] = useState();

  const ScribeApiHander = async (values) => {
    setIsLoading(true);

    try {
      const res = await Axios({
        method: "POST",
        url: apiConfig.subscribeToNewsLetter,
        headers: {
          token: window.localStorage.getItem("token"),
        },
        data: {
          email: values.email,
        },
      });
      if (res.data.responseCode === 200) {
        toast.success(res.data.responseMessage);
        setScribe(res.data.data);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box className={classes.footerSection}>
        <Box>
          <Box className="subpart">
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                  <Box>
                    <img
                      src="/images/VD1.png"
                      alt="logo"
                      width="100%"
                      className={classes.LogoStyle}
                      style={{
                        maxWidth: "230px",
                        margin: "0 auto",
                        cursor: "pointer",
                      }}
                    />
                  </Box>{" "}
                  <Typography
                    variant="body1"
                    style={{ textAlign: "left", color: "#fff" }}
                  >
                    VD Blocks UAB - Registered and Authorised to act as a
                    Virtual Assets Service Provider (VASP) from Republic of
                    Lithuania vide registration number 305970268.
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={2}>
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={12}
                      className={classes.fixedborder}
                    >
                      {/* <Typography variant="h3">
                        <span
                          style={{
                            borderBottom: "2px solid #3483D2",
                            paddingBottom: "8px",
                          }}
                        >
                          Menu
                        </span>
                      </Typography> */}
                      <List className={classes.listbox}>
                        <RouterLink
                          className="hovertext"
                          // to="/roadmap"
                          style={{ textDecoration: "none", color: "#7B838F" }}
                          to="/blog"
                        >
                          <ListItem>Blog</ListItem>
                        </RouterLink>
                        <RouterLink
                          className="hovertext"
                          to="/contact"
                          style={{ textDecoration: "none", color: "#7B838F" }}
                        >
                          <ListItem>Contact Us</ListItem>
                        </RouterLink>
                        <RouterLink
                          className="hovertext"
                          to="/faqs"
                          style={{ textDecoration: "none", color: "#7B838F" }}
                        >
                          <ListItem>FAQ</ListItem>
                        </RouterLink>
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3}>
                  {/* <Typography variant="h3">
                    <span
                      style={{
                        borderBottom: "2px solid #3483D2",
                        paddingBottom: "8px",
                      }}
                    >
                      Company
                    </span>
                  </Typography> */}
                  <List className={classes.listbox}>
                    <RouterLink
                      className="hovertext"
                      to="/terms"
                      style={{ textDecoration: "none", color: "#7B838F" }}
                    >
                      <ListItem>Terms and Conditions</ListItem>
                    </RouterLink>
                    <RouterLink
                      className="hovertext"
                      to="/privacy-policy"
                      style={{ textDecoration: "none", color: "#7B838F" }}
                    >
                      <ListItem>Privacy Policy</ListItem>
                    </RouterLink>
                    <RouterLink
                      className="hovertext"
                      to="/legal"
                      style={{ textDecoration: "none", color: "#7B838F" }}
                    >
                      <ListItem>Cookies Policy</ListItem>
                    </RouterLink>
                    <a
                      href="images/AML-policy.pdf"
                      target="_blank"
                      download="AML-policy.pdf"
                      style={{
                        textDecoration: "none",
                        color: "#7B838F",
                      }}
                    >
                      <ListItem>AML & CTF Policy</ListItem>
                    </a>
                    {/* <RouterLink
                      className="hovertext"
                      to="#"
                      AML-policy.docx
                      style={{ textDecoration: "none", color: "#7B838F" }}
                    > */}
                    <a
                      href="images/Information.pdf"
                      target="_blank"
                      download="Information.pdf"
                      style={{
                        textDecoration: "none",
                        color: "#7B838F",
                      }}
                    >
                      <ListItem>Information Security Policy</ListItem>
                    </a>
                    {/* </RouterLink> */}
                  </List>{" "}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  className={classes.fixedborder}
                >
                  <Formik
                    onSubmit={(values, { resetForm }) => {
                      ScribeApiHander(values);
                      resetForm();
                    }}
                    initialValues={{
                      email: "",
                    }}
                    initialStatus={{
                      success: false,
                      successMsg: "",
                    }}
                    validationSchema={yep.object().shape({
                      email: yep
                        .string()
                        .email(
                          "You have entered an invalid email address. Please try again"
                        )
                        .required("Email address is required.")
                        .matches("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"),
                    })}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      touched,
                      values,
                      setFieldValue,
                    }) => (
                      <Form>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          className={classes.fixedborder}
                        >
                          <Box>
                            <Typography
                              variant="h4"
                              style={{
                                marginTop: "20px",
                                marginBottom: "10px",
                                color: "#fff",
                              }}
                            >
                              Subscribe to Newsletter
                            </Typography>
                            <Box className={classes.feildBox}>
                              <TextField
                                placeholder="Enter your mail"
                                className={classes.textFeild}
                                name="email"
                                variant="outlined"
                                value={values.email}
                                error={Boolean(touched.email && errors.email)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  className: classes.bin,
                                  endAdornment: (
                                    <Button
                                      variant="contained"
                                      type="submit"
                                      color="secondary"
                                      className={classes.textBtn}
                                    >
                                      Join Now
                                    </Button>
                                  ),
                                }}
                              />
                              <FormHelperText
                                error
                                style={{ fontSize: "12px" }}
                              >
                                {touched.email && errors.email}
                              </FormHelperText>
                            </Box>
                          </Box>
                        </Grid>
                      </Form>
                    )}
                  </Formik>

                  {/* <List className={classes.listbox}>
                    <RouterLink
                      className="hovertext"
                      style={{ textDecoration: "none", color: "#7B838F" }}
                      to="#"
                    >
                      <ListItem>United Kingdom</ListItem>
                    </RouterLink>
                    <ScrollLink
                      className="hovertext"
                      style={{
                        textDecoration: "none",
                        color: "#7B838F",
                        cursor: "pointer",
                      }}
                      to="#"
                    >
                      <ListItem>Lithuania</ListItem>
                    </ScrollLink>
                    <ScrollLink
                      className="hovertext"
                      style={{
                        textDecoration: "none",
                        color: "#7B838F",
                        cursor: "pointer",
                      }}
                      to="#"
                    >
                      <ListItem>UAE</ListItem>
                    </ScrollLink>
                    <ScrollLink
                      className="hovertext"
                      style={{
                        textDecoration: "none",
                        color: "#7B838F",
                        cursor: "pointer",
                      }}
                      to="#"
                    >
                      <ListItem>Mauritius</ListItem>
                    </ScrollLink>
                    <ScrollLink
                      className="hovertext"
                      style={{
                        textDecoration: "none",
                        color: "#7B838F",
                        cursor: "pointer",
                      }}
                      to="#"
                    >
                      <ListItem>Dominica</ListItem>
                    </ScrollLink>
                  </List> */}
                </Grid>
              </Grid>

              <Box className={classes.socialIcons} mt={3}>
                <Link
                  href="https://www.facebook.com/people/VDBC-Group/100090834414870/"
                  target="_blank"
                >
                  <FaFacebookF className={classes.icon} />
                </Link>
                <Link href="https://twitter.com/VDBCGroup" target="_blank">
                  <AiOutlineTwitter className={classes.icon} />
                </Link>

                <Link
                  href="https://www.instagram.com/vdbcgroup/?igshid=YmMyMTA2M2Y%3D"
                  target="_blank"
                >
                  <FiInstagram className={classes.icon} />
                </Link>
                {/* <Link
                  href="https://www.linkedin.com/in/vdb-coin-238157255/"
                  target="_blank"
                >
                  <LinkedInIcon className={classes.icon} />
                </Link>
                <Link
                  href="https://www.reddit.com/user/VDBCoin"
                  target="_blank"
                >
                  <RedditIcon className={classes.icon} />
                </Link>
                <Link
                  href="https://medium.com/@marketing_66757"
                  target="_blank"
                >
                  <SiMedium className={classes.icon} />
                </Link> */}
                {/* <br />

                <Link
                  href=" https://app.slack.com/client/T048XC6E7EJ/C048XC6FJ6S/rimeto_profile/U049TM4776C"
                  target="_blank"
                >
                  <IoLogoSlack className={classes.icon} />
                </Link>

                <Link
                  href="https://bitcointalk.org/index.php?action=profile "
                  target="_blank"
                >
                  <FaBitcoin className={classes.icon} />
                </Link> */}
              </Box>

              <Box style={{ paddingTop: "12px" }} pb={3}>
                <Box
                  style={{ borderTop: "1px solid rgb(207 207 207 / 21%)" }}
                ></Box>
              </Box>

              <Box className={classes.baseSection}>
                <Box>
                  <Typography variant="body1">
                    VDBC &copy; 2023 . All Rights Reserved.
                  </Typography>
                </Box>
              </Box>
            </Container>
          </Box>
        </Box>
      </Box>
    </>
  );
}
