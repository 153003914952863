import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/privacy")),
  },
  {
    exact: true,
    path: "/aml-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/AmlPolicy")),
  },

  {
    exact: true,
    path: "/legal",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Legal")),
  },
  {
    exact: true,
    path: "/license",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Leince")),
  },
  {
    exact: true,
    path: "/disclaimer",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Disclaimer")),
  },
  {
    exact: true,
    path: "/terms",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Term")),
  },
  {
    exact: true,
    path: "/roadmap",
    layout: HomeLayout,
    component: lazy(() => import("src/component/RoadMap")),
  },

  {
    exact: true,
    path: "/banking1",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/VDToken/Index")),
  },
  {
    exact: true,
    path: "/vd-market",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/VD Exchange/Index")),
  },
  {
    path: "/banking",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/VDWallet/Index")),
  },
  {
    exact: true,
    path: "/virtual",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/VDBank/Index")),
  },
  {
    exact: true,
    path: "/contact",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/ContactUs")),
  },
  {
    exact: true,
    path: "/faqs",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Faqs")),
  },
  {
    exact: true,
    path: "/blog",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Blog")),
  },
  {
    exact: true,
    path: "/team",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Team")),
  },

  {
    component: () => <Redirect to="/404" />,
  },
];
